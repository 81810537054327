import { randomNumber, randomFrom } from '../helpers'

const quizReducer = (state, action) => {
  switch(action.type) {
    case "UPDATE_NUMBERS":
      return {
        ...state,
        target: randomNumber(),
        multiplier: randomNumber()
      }
    case "RESET_QUIZ":
      return {
        ...state,
        correctAnswer: null,
        chosenAnswer: null,
        submitted: false
      }
    case "SET_ANSWER":
      return {
        ...state,
        chosenAnswer: action.answer
      }
    case "SET_CORRECT_ANSWER":
      if (state.correctAnswer !== null) {
        return state
      }

      const answerOptions = action.answerOptions
      const correctAnswer = randomFrom(answerOptions)
      return {
        ...state,
        correctAnswer
      }
    case "SUBMIT_ANSWER":
      return {
        ...state,
        submitted: true
      }
    case "ADD_SCORE":
      return {
        ...state,
        scores: state.scores.concat(action.score)
      }
    default:
        return state
  }
}

export default quizReducer
